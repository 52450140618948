export function Banner() {
  return (
    <div style={{ backgroundColor: '#f8f9fa', height: 'auto' }}>
      <div className="justify-content-center pt-3 pb-1">
        <h2 style={{ 
          fontSize: '20px', 
          textAlign: 'center', 
          paddingLeft: '10px', 
          paddingRight: '10px' 
        }}>
          <a href='/erbjudande' className="black-link">
          <span>Upp till <strong>50% rabatt</strong> på våra erbjudanden!</span>
          </a>
        </h2>
      </div>
    </div>
  );
}

export default Banner;
