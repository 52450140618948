import React, { useState, useEffect } from "react";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import items from "../../data/items.json";
import emailjs from "emailjs-com";
import Popup from 'reactjs-popup';
import Spinner from './Spinner'; 
import { Helmet } from "react-helmet";

const Checkout = () => {
  const { cartItems, getTotalPrice, removeFromCart, addItemToCart  } = useShoppingCart();
  const [cartItems1, setCartItem1] = useState("");
  const [cartItems2, setCartItem2] = useState("");
  const [cartItems3, setCartItem3] = useState("");
  const [cartItems4, setCartItem4] = useState("");
  const [cartItems5, setCartItem5] = useState("");
  const [cartItems6, setCartItem6] = useState("");
  const [cartItems7, setCartItem7] = useState("");
  const [cartItems8, setCartItem8] = useState("");
  const [cartItems9, setCartItem9] = useState("");
  const [cartItems10, setCartItem10] = useState("");
  const [cartItems11, setCartItem11] = useState("");
  const [cartItems12, setCartItem12] = useState("");
  const [cartItems13, setCartItem13] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [order, setOrder] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sverige");
  const [mobile, setMobile] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipError, setZipError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [cartError, setCartError] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false); 
  const [discountApplied, setDiscountApplied] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Swish");
  const [selectedPaymentNumber] = useState("0739341630");
  const [selectedWebsite] = useState("ViagraShop");

  const handleAddItem = (id, quantity) => {
    if (getTotalPrice() < 0) {
      return;
    }
  
    const itemInCart = cartItems.find((item) => item.id === id);

    if (id === 39) {
      if (!itemInCart) {
        addItemToCart(id, quantity);
      }
    } else {
      addItemToCart(id, quantity);
    }
  };
  
  useEffect(() => {
    generateOrderNumber();
    updateCartItems();
    updateTotalPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, discountAmount]);
  
  const updateTotalPrice = () => {
    const totalPrice = getTotalPrice() - discountAmount;
    setTotalPrice(totalPrice);
  };

  const validateTotalPrice = (totalPrice) => {
    return totalPrice > 0;
  };

  const generateOrderNumber = () => {
    const randomOrderNumber = Math.floor(1000 + Math.random() * 9000); 
    setOrder(randomOrderNumber.toString());
  };

  const updateCartItems = () => {
    const updatedCart = cartItems.map(cartItem => {
      const item = items.find(item => item.id === cartItem.id);
      return `${cartItem.quantity} x ${item?.name} , ${item?.quantities}-Pack (${item?.price}kr) `;
    });

    if (updatedCart[0] !== null && updatedCart[0] !== undefined) { 
      setCartItem1(updatedCart[0]); 
    }
    if (updatedCart[1] !== null && updatedCart[1] !== undefined) { 
      setCartItem2(updatedCart[1]); 
    }
    if (updatedCart[2] !== null && updatedCart[2] !== undefined) { 
      setCartItem3(updatedCart[2]); 
    }
    if (updatedCart[3] !== null && updatedCart[3] !== undefined) { 
      setCartItem4(updatedCart[3]); 
    }
    if (updatedCart[4] !== null && updatedCart[4] !== undefined) { 
      setCartItem5(updatedCart[4]); 
    }
    if (updatedCart[5] !== null && updatedCart[5] !== undefined) { 
      setCartItem6(updatedCart[5]); 
    }
    if (updatedCart[6] !== null && updatedCart[6] !== undefined) { 
      setCartItem7(updatedCart[6]); 
    }
    if (updatedCart[7] !== null && updatedCart[7] !== undefined) { 
      setCartItem8(updatedCart[7]); 
    }
    if (updatedCart[8] !== null && updatedCart[8] !== undefined) { 
      setCartItem9(updatedCart[8]); 
    }
    if (updatedCart[9] !== null && updatedCart[9] !== undefined) { 
      setCartItem10(updatedCart[9]); 
    }
    if (updatedCart[10] !== null && updatedCart[10] !== undefined) { 
      setCartItem11(updatedCart[10]); 
    }
    if (updatedCart[11] !== null && updatedCart[11] !== undefined) { 
      setCartItem12(updatedCart[11]); 
    }
    if (updatedCart[12] !== null && updatedCart[12] !== undefined) { 
      setCartItem13(updatedCart[12]); 
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    updateCartItems();

    const emailParams = {
      from_name: name,
      from_address: address,
      from_zip: zip,
      from_city: city,
      from_country: country,
      from_email: email,
      from_mobile: mobile,
      order_number: order,
      discount_code: discountCode,
      discount_amount: discountAmount,
      total_price: totalPrice,
      cart_Items1: cartItems1,
      cart_Items2: cartItems2,
      cart_Items3: cartItems3,
      cart_Items4: cartItems4,
      cart_Items5: cartItems5,
      cart_Items6: cartItems6,
      cart_Items7: cartItems7,
      cart_Items8: cartItems8,
      cart_Items9: cartItems9,
      cart_Items10: cartItems10,
      cart_Items11: cartItems11,
      cart_Items12: cartItems12,
      cart_Items13: cartItems13,
      selected_payment_method: selectedPaymentMethod, 
      selected_payment_number: selectedPaymentNumber, 
      selected_website: selectedWebsite, 
    };

    let isValid = true;
    if (!validateName(name)) {
      isValid = false;
      setNameError("Namnet är ogiltigt");
    } else {
      setNameError("");
    }
    if (!validateAddress(address)) {
      isValid = false;
      setAddressError("Adressen är ogiltigt");
    } else {
      setAddressError("");
    }
    if (!validateCity(city)) {
      isValid = false;
      setCityError("Staden är ogiltigt");
    } else {
      setCityError("");
    }
    if (!validateZip(zip)) {
      isValid = false;
      setZipError("Postnumret är ogiltigt");
    } else {
      setZipError("");
    }
    if (!validateEmail(email)) {
      isValid = false;
      setEmailError("E-postadressen är ogiltigt");
    } else {
      setEmailError("");
    }
    if (!validateMobile(mobile)) {
      isValid = false;
      setMobileError("Mobilnumret är ogiltigt, ange 8-10 siffror");
    } else {
      setMobileError("");
    }
    if (!validateTotalPrice(totalPrice)) {
      isValid = false;
      setCartError("Varukorgen är tom!");
    }

    if (isValid) {
      setIsSendingEmail(true);
  
      try {
        const response = await emailjs.send(
          "service_ia0mvri",
          "template_txjhaon",
          emailParams,
          "lxgOFrVQtj196ZPra"
        );
        
        console.log("Email sent:", response.status);
        clearFormAndCart();
        setShowPopup(true);
      } catch (error) {
        console.error("Error sending email:", error);
  
        if (error.status === 412) {
          setTimeout(sendEmail, 1000);
          return;
        }
      } finally {
        setIsSendingEmail(false);
      }
    }
  };

  const clearFormAndCart = () => {
    setName("");
    setEmail("");
    setAddress("");
    setZip("");
    setCity("");
    setCountry("Sverige");
    setMobile("");
    setCartItem1("");
    setCartItem2("");
    setCartItem3("");
    setCartItem4("");
    setCartItem5("");
    setCartItem6("");
    setCartItem7("");
    setCartItem8("");
    setCartItem9("");
    setCartItem10("");
    setCartItem11("");
    setCartItem12("");
    setCartItem13("");
    setDiscountCode("");
    setDiscountAmount("");
    setDiscountApplied(false);
    setSelectedPaymentMethod("Swish"); 
  
    cartItems.forEach((item) => removeFromCart(item.id));
  };

  const validateName = (name) => {
    const re = /^[a-öA-Ö\s-]{4,}$/;
    return re.test(name);
  };

  const validateAddress = (address) => {
    const re = /^[a-öA-Ö0-9\s]{4,}$/;
    return re.test(address);
  };
  
  const validateCity = (city) => {
    const re = /^[a-öA-Ö\s]{2,}$/;
    return re.test(city);
  };

  const validateZip = (zip) => {
    const re = /^\d{4,}$/;
    return re.test(zip);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateMobile = (mobile) => {
    const re = /^\d{8,}$/;
    return re.test(mobile);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const [discountError, setDiscountError] = useState("");

  const handleApplyDiscount = () => {
    const total = getTotalPrice(); 
  
    if (discountCode.toLowerCase() === "hård10") {
      const discount = total * 0.1;
      const roundedDiscount = Math.round(discount);
      setDiscountAmount(roundedDiscount);
      setDiscountApplied(true);
      setDiscountError(""); 
    } else if (discountCode.toLowerCase() === "twitter") {  
      const discount = total * 0.1;
      const roundedDiscount = Math.round(discount);
      setDiscountAmount(roundedDiscount);
      setDiscountApplied(true);
      setDiscountError(""); 
    } else if (discountCode.toLowerCase() === "vip20") {  
      const discount = total * 0.20;
      const roundedDiscount = Math.round(discount);
      setDiscountAmount(roundedDiscount);
      setDiscountApplied(true);
      setDiscountError(""); 
    } else if (discountCode.toLowerCase() === "hemlig99") {
      if (total >= 1) { 
        handleAddItem(39, 1);
        const discount = 219;
        const roundedDiscount = Math.round(discount);
        setDiscountAmount(roundedDiscount);
        setDiscountApplied(true);
        setDiscountError(""); 
      } else {
        setDiscountError("Rabattkoden kräver ett minimibelopp på 1 kr.");
        setDiscountAmount(0);
        setDiscountApplied(false);
      }
    } else {
      setDiscountAmount(0);
      setDiscountApplied(false);
      setDiscountError(""); // Clear any previous error messages
    }
  };
  
  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };
  
  return (
    <section>
      <Helmet>
      <title>Köp Kamagra 100mg, Kamagra Jelly och Viagra online från Sverige - ViagraShop.se</title>
      <meta name="description" content="Köp potensmedel för män som är bättre än Viagra. Vi erbjuder ett brett utbud av potensmedel online utan recept." />
      </Helmet>
      <main className="checkout">
        <div className="center-content">
          <p></p>
          <h1>Kassa</h1>
          <p></p>
          <div>
          <h2>Varukorg</h2>
          {cartError && <p className="error">{cartError}</p>}
          <ul>
            {cartItems.map((cartItem) => {
              const item = items.find((item) => item.id === cartItem.id);
              return (
                <li key={cartItem.id}>
                  <div className="margin-less">
                    <img src={item.imgUrl} alt={item.name} style={{ width: '50px', height: '50px', borderRadius: '25px' , marginRight: '3px', marginBottom: '5px' }} />
                    {cartItem.quantity} x {item.shortname} {item.quantities}-Pack ({item.price} kr) 
                  </div>
                </li>
              );
            })}
          </ul>
          </div>
            <div className="discountcode">
            <input
              type="text"
              placeholder="Ange en rabattkod"
              value={discountCode}
              onChange={(e) => setDiscountCode(e.target.value)}
            />
            <button onClick={handleApplyDiscount}>Tillämpa rabattkod</button>
            {discountError && <p className="error">{discountError}</p>}
            {discountCode.toLowerCase() !== "hård10" && 
            discountCode.toLowerCase() !== "twitter" && 
            discountCode.toLowerCase() !== "vip20" && 
            discountCode.toLowerCase() !== "hemlig99" && 
            discountCode.trim() !== "" && (
              <p className="error">Denna kod är inte giltig.</p>
            )}
          </div>
          {discountApplied && (
            <div className="payment">
              <div>
                <strong>Rabatt:</strong> -{discountAmount} kr
              </div>
              <div>
              <strong>Totalkostnad:</strong> {getTotalPrice() - discountAmount} kr
            </div>
            <div className="payment-methods">
              <strong>Betalningssätt: </strong>  
              <br></br>
              <input
                type="radio"
                id="swish"
                value="Swish"
                checked={selectedPaymentMethod === "Swish"}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="swish"> Swish </label>
              <input
                type="radio"
                id="card"
                value="Kortbetalning"
                checked={selectedPaymentMethod === "Kortbetalning"}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="card"> Kortbetalning</label>
            </div>
            </div>
          )}
            {!discountApplied && ( 
            <div className="payment">
            <div>
              <strong>Totalkostnad:</strong> {getTotalPrice() - discountAmount} kr
            </div>
            <div className="payment-methods">
              <strong>Betalningssätt: </strong>  
              <br></br>
              <input
                type="radio"
                id="swish"
                value="Swish"
                checked={selectedPaymentMethod === "Swish"}
                onChange={handlePaymentMethodChange}
              />
              <label htmlFor="swish"> Swish </label>
              <input
                type="radio"
                id="card"
                value="Kortbetalning"
                checked={selectedPaymentMethod === "Kortbetalning"}
                onChange={handlePaymentMethodChange}
                
              />
              <label htmlFor="card"> Kortbetalning</label>
            </div>
          </div>
          )}
          <h2>Leveransadress</h2>
            <h5>Fullständigt namn</h5>
            <input
              type="text"
              placeholder="Ange för och efternamn"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {nameError && <p className="error">{nameError}</p>}
            <h5>Gatuadress</h5>
            <input
              type="text"
              placeholder="Ange gatuadress"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {addressError && <p className="error">{addressError}</p>}
            <h5>Postnummer</h5>
            <input
              type="text"
              placeholder="Ange postnummer"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            {zipError && <p className="error">{zipError}</p>}
            <h5>Stad</h5>
            <input
              type="text"
              placeholder="Ange stad"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {cityError && <p className="error">{cityError}</p>}
            <h5>Land</h5>
              <select
                className ="list-country"
                aria-label="Välj land"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="Sverige">Sverige</option>
                <option value="Danmark">Danmark</option>
                <option value="Finland">Finland</option>
              </select>
            <h5>Mobilnummer</h5>
            <input
              type="text"
              placeholder="Ange mobilnummer"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            {mobileError && <p className="error">{mobileError}</p>}
            <h5>E-postadress</h5>
            <input
              type="text"
              placeholder="Ange E-postadress"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="error">{emailError}</p>}
            <p></p>
            <button type="submit" onClick={sendEmail} className="information">Lägg beställning</button>
            <Popup open={isSendingEmail} closeOnDocumentClick={false} modal>
              <div style={{ color: "#000", backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',alignItems: 'center',justifyContent: 'center', textAlign: 'center', width: 'auto' }}>
              <p>Vänligen vänta medan vi behandlar din beställning..</p>
              <Spinner />
              </div>
            </Popup>
          <Popup open={showPopup} onClose={closePopup}>
            <div style={{ color: "#000", backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',alignItems: 'center',justifyContent: 'center', textAlign: 'center', width: 'auto' }}>
            <h3>Tack för din beställning!</h3>
            <p>Du kommer snart att motta ett e-postmeddelande med betalningsinformation.</p>
            <p>Om du inte ser e-postmeddelande inom 1 minut, vänligen kontrollera din skräppost.</p>
            </div>
          </Popup>
        </div>
       </main> 
    </section>
  );
};

export default Checkout;
