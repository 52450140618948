import React from "react";
import { FaTwitter } from "react-icons/fa";

const Footer = () => { 
    return (
        <footer>
            <div className="main-container">
                <div className="info-container"> 
                    <strong>
                        <span>©2025 ViagraShop</span>  
                        <a href='/omoss' className='navigation-link'>
                            <span>Kontakta oss</span>
                        </a> 
                        <a href='/faq' className='navigation-link'>
                            <span>Vanliga frågor</span>
                        </a> 
                    </strong>
                </div> 

                <div className="extra-container"> 
                    <span>
                        <strong>Följ ViagraShop</strong>
                        <a 
                            href="https://x.com/viagrashopse" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            aria-label="Följ ViagraShop på Twitter"
                        >
                            <FaTwitter className="twitter-icon" aria-hidden="true"/>
                        </a>
                    </span>
                </div> 
            </div>

            <div className="image-container"> 
                <img 
                    src="/imgs/visa.png" 
                    alt="Visa-kortbetalning" 
                />   
                <img 
                    src="/imgs/mastercard.png" 
                    alt="MasterCard-kortbetalning" 
                />   
                <img 
                    src="/imgs/swish.png" 
                    alt="Swish-betalning" 
                />   
            </div>
        </footer>
    );
};

export default Footer;
