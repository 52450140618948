import { Col, Row } from "react-bootstrap"
import { StoreItem } from "../../cart/StoreItem"
import storeItems from "../../../data/items.json"

export function Vidalista() {
  const desiredIds = [25,26,27,28,29,30,50,51,52];
  const filteredItems = storeItems.filter(item => desiredIds.includes(item.id));
  
  return (
    <section>
 
      <div>
        <>
          <Row xs={1} sm={1} md={2} lg={3} xl={3} className="product-display g-3">
            {filteredItems.map(item => (
              <Col key={item.id}>
                <StoreItem {...item} showLink={false} /> 
              </Col>
            ))}
          </Row>
        </>
      </div>
    </section>
  );
}
