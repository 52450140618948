import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
    return (
        <>
            <Helmet>
                <title>ViagraShop: Köp Viagra receptfritt och andra potensmedel för att lösa dina potensproblem</title>
                <meta name="description" content="ViagraShop: Vi erbjuder receptfria potenshöjande medel som effektivt hjälper mot erektionsproblem. Utforska vårt utbud av de mest populära potensmedlen för män." />
                <meta name="keywords" content="viagrastore, viagra store,köp potensmedel, köpa potensmedel, potensmedel Sverige, billig potensmedel, diskret leverans potensmedel, potensmedel leverans Sverige, potensmedel pris, potensmedel billigt, potensmedel online"/>
                <link rel="canonical" href="https://viagrashop.se/omoss" />

                {/* Open Graph */}
                <meta property="og:title" content="Köp Viagra receptfritt och andra potensmedel för att lösa dina potensproblem" />
                <meta property="og:description" content="Vi erbjuder receptfria potenshöjande medel som effektivt hjälper mot erektionsproblem. Utforska vårt utbud av de mest populära potensmedlen för män." />
                <meta property="og:url" content="https://viagrashop.se/omoss" />
                <meta property="og:type" content="website" />
                
                {/* Twitter Card */}
                <meta name="twitter:title" content="Köp Viagra receptfritt och andra potensmedel för att lösa dina potensproblem" />
                <meta name="twitter:description" content="Vi erbjuder receptfria potenshöjande medel som effektivt hjälper mot erektionsproblem. Utforska vårt utbud av de mest populära potensmedlen för män." />
                <meta name="twitter:url" content="https://viagrashop.se/omoss" />
                <meta name="twitter:card" content="summary" />

                {/* X-Content-Type-Options */}
                <meta http-equiv="X-Content-Type-Options" content="nosniff" />
            </Helmet>
            <main>
                <article className="nav-center">
                <a href='/' className="nav-link">Hem / </a>
                <a href='/omoss' className="nav-link">Om oss</a>
                </article>
                <header className="article-container">
                    <h1>Om oss</h1>
                    <p>ViagraShop grundades 2022 i Sverige.</p>
                    <p>Vi har sedan starten vuxit snabbt och är idag en av Sveriges ledande aktörer inom potensmedel.</p>
                    <p>Vi tillhandahåller potenshöjande läkemedel till svenska kunder utan recept.</p>
                    <p>Alla våra produkter kommer självklart i originalförpackningar.</p>
                    <p>Det som skiljer oss från andra aktörer på marknaden är att vi levererar alla beställningar inom Sverige på bara 2–3 arbetsdagar, direkt till din brevlåda.</p>
                    <p>Vi är stolta över att ha fått många positiva <a href='/omdomen' className="nav-link">omdömen</a> från våra kunder. Våra kunder uppskattar vår snabba och pålitliga service samt de konkurrenskraftiga priserna på våra produkter.</p>
                    <p>Våra priser är betydligt förmånligare än vad du hittar på svenska apotek.</p>
                    <p>Efter att din beställning har skickats, raderas alla dina personuppgifter från oss.</p>
                </header>
                
                <article className="article-container">
                    <h2>Vårt sortiment</h2>
                    <p>Vi erbjuder en rad potensmedel anpassade efter olika behov.</p>
                    <p>Alla våra produkter kommer självklart i originalförpackningar.</p>
                    <p>Alla våra produkter produceras av globala läkemedelsföretag som Pfizer, Eli Lilly och Ajanta.</p>
                    <p>Bland våra populära produkter finns Kamagra, Vidalista, Viagra, Cialis samt Lovegra.</p>
                </article>
                
                <article className="article-container">
                    <h2>Leverans och betalning</h2>
                    <p>Vi accepterar kortbetalningar med Visa eller Mastercard samt Swish.</p>
                    <p>Efter att du har lagt en beställning hos oss kommer du att få en orderbekräftelse via e-post.</p>
                    <p>I e-postmeddelandet kommer du att få betalningsinformation som hjälper dig att slutföra både betalningen och din beställning.</p>
                    <p>Beställningar som betalas innan kl. 11.00 skickas samma dag.</p>
                    <p>Efter att din beställning har skickats får du ett bekräftelsemail med Kolli-ID för spårning.</p>
                    <p>Vi samarbetar med PostNord för diskret och säker leverans direkt till din brevlåda på endast 2–3 arbetsdagar.</p>
                </article>

                <article className="article-container">
                    <h2>Köpvillkor</h2>
                    <h3>Villkor</h3>
                    <p>För att handla på vår webbplats måste du vara minst 18 år gammal. När du bekräftar ditt köp genom att skicka din order accepterar du också våra köpvillkor och ingår därmed ett bindande avtal.</p>
                    <p>När det gäller produktansvar uppmanar vi alltid våra kunder att rådfråga en läkare vid sjukdom, allergi eller överkänslighet och att läsa tillverkarens instruktioner och produktinformation innan användning.</p>
                    <p>Genom att beställa våra produkter och godkänna detta avtal medger du att du kan svara “NEJ” på nedanstående 5 frågor.</p>
                    <p>(Är du osäker rekommenderar vi att du rådgör med en läkare innan någon produkt från oss intas.)</p>
                    <p>1. Är du allergisk mot Sildenafil, Tadalafil eller någon annan substans som våra produkter innehåller?</p>
                    <p>2. Använder du några läkemedel som innehåller nitrater?</p>
                    <p>3. Har du lågt blodtryck?</p>
                    <p>4. Har du hjärt- eller leverproblem?</p>
                    <p>5. Har du haft en hjärtattack?</p>
                    <h3>Tryckfel</h3>
                    <p>Vi förbehåller oss rätten att korrigera eventuella tryckfel eller felaktiga priser på webbplatsen och kan avbryta en order om sådana fel upptäcks.</p>
                    <h3>Personuppgifter</h3>
                    <p>Personuppgifter i enlighet med dataskyddslagen behöver vi ditt medgivande för att behandla dina personuppgifter. All information du ger oss, eller som vi får tillgång till, kommer att hanteras av ViagraShop.se som ansvarig för personuppgifter. Denna information kan användas för att garantera leveranser till dig.</p>
                    <p>Efter att din beställning har skickats, raderas alla dina personuppgifter från oss.</p>
                    <h3>Ångerrätt & Returpolicy</h3>
                    <p>För läkemedel som vanligtvis kräver recept gäller inte den vanliga ångerrätten eller distansavtalslagen som ger 14 dagars öppet köp. Därför kan du inte ångra ditt köp efter att ordern har lagts.</p>
                    <h3>Reklamationer</h3>
                    <p>Kontakta oss inom 5 dagar efter mottagandet vid reklamation av defekta produkter. Vi accepterar inte reklamationer på grund av allergiska reaktioner. ViagraShop.se ansvarar inte för skador som kan uppstå vid produkternas användning. Personer med allergier bör rådfråga tillverkaren eller en läkare angående produktens innehåll innan beställning.</p>
                </article>

                <article className="article-container">
                    <h2>Kontakta oss</h2>
                    <p>Innan du kontaktar oss rekommenderar vi att du tar en titt på vår  <a href='/faq' className="black-link">
                    <span>FAQ</span></a>  där du hittar svar på de vanligaste frågorna.</p>
                    <p>Vi finns här för att hjälpa dig, så tveka inte att kontakta oss genom att skicka ett mail till order@viagrashop.se.
                     Vi svarar vanligtvis inom 24 timmar.</p>
                </article>
            </main>
        </>
    );
};

export default About;
