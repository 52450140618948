import { Button, Navbar as NavbarBs, Col } from "react-bootstrap";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { FiShoppingCart } from "react-icons/fi";
import { FaShippingFast } from "react-icons/fa";
import DropdownMenu from "./DropdownMenu";

export function Header() {
  const { openCart, cartQuantity } = useShoppingCart();
 
  return (
    <NavbarBs>
      <Col className="d-flex justify-content-start align-items-center">
      <a href='/'>
        <div style={{ textAlign: 'left' }}>
              <img src="/imgs/logo.png" alt="viagrastore" style={{ maxWidth: '150px', height: 'auto'  }} />
        </div>
      </a>
      </Col>
      <Col className="header-text justify-content-center pt-2">
      <h2 style={{ fontSize: '14px', textAlign: 'center' }}>
      <FaShippingFast size={18}/> Fri frakt över 499 kr. Diskret leverans till din brevlåda inom 2–3 arbetsdagar.
      </h2>
      </Col>
      <Col className="d-flex justify-content-end align-items-center">
        <DropdownMenu />
        {cartQuantity >= 0 && (
          <div className="cart-icon">
            <Button
              className="cart-button"
              onClick={openCart}
              style={{ position: "relative" }}
              variant="dark"
            >
              <FiShoppingCart size={20} />
              <div
                className="rounded-circle d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#00008b", 
                  color: "white",
                  width: "1.5rem",
                  height: "1.5rem",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  transform: "translate(25%, 25%)",
                }}
              >
                {cartQuantity}
              </div>
            </Button>
          </div>
        )}
      </Col>
    </NavbarBs>
  );
}

export default Header;
