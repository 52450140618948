import React from "react";
import { Helmet } from "react-helmet";

const FAQ = () => {
    return (
        <>
            <Helmet>
                <title>ViagraShop: Potensmedel Online i Sverige - Köp Effektiva och Säkra Potenshöjande Medel Här</title>
                <meta name="description" content="Upptäck vårt sortiment av potensmedel online från Sverige. Vi erbjuder effektiva och säkra lösningar för att förbättra din sexuella hälsa. Köp diskret och få snabb leverans till ditt hem." />
                <meta name="keywords" content="viagrastore, viagra store, potensmedel, potenshöjande medel, köp potensmedel, potensmedel online, potensmedel Sverige, säkra potensmedel, diskret köp potensmedel, naturliga potensmedel, effektiva potensmedel, köp potenshöjande medel online" />
                <link rel="canonical" href="https://viagrashop.se/faq" />
                
                {/* Open Graph */}
                <meta property="og:title" content="Potensmedel Online i Sverige - Köp Effektiva och Säkra Potenshöjande Medel Här" />
                <meta property="og:description" content="Upptäck vårt sortiment av potensmedel online från Sverige. Vi erbjuder effektiva och säkra lösningar för att förbättra din sexuella hälsa. Köp diskret och få snabb leverans till ditt hem." />
                <meta property="og:url" content="https://viagrashop.se/faq" />
                <meta property="og:type" content="website" />
                
                {/* Twitter Card */}
                <meta name="twitter:title" content="Potensmedel Online i Sverige - Köp Effektiva och Säkra Potenshöjande Medel Här" />
                <meta name="twitter:description" content="Upptäck vårt sortiment av potensmedel online från Sverige. Vi erbjuder effektiva och säkra lösningar för att förbättra din sexuella hälsa. Köp diskret och få snabb leverans till ditt hem." />
                <meta name="twitter:url" content="https://viagrashop.se/faq" />
                <meta name="twitter:card" content="summary" />

                {/* X-Content-Type-Options */}
                <meta http-equiv="X-Content-Type-Options" content="nosniff" />
            </Helmet>
            <main>
                <article className="nav-center">
                <a href='/' className="nav-link">Hem / </a>
                <a href='/faq' className="nav-link">FAQ</a>
                </article>
                <article className="article-container">
                    <h1>FAQ</h1>
                    <p>Här hittar du svar på våra vanligaste frågor från kunder. Om du har ytterligare frågor är du alltid välkommen att kontakta vår kundtjänst.</p>

                    <h3>1. Vad är ViagraShop och när grundades företaget?</h3>
                    <p>ViagraShop grundades 2022 i Sverige och är en av Sveriges ledande aktörer inom potensmedel.</p>

                    <h3>2. Vilka produkter erbjuder ViagraShop?</h3>
                    <p>Vi erbjuder potensmedel som Kamagra, Vidalista, Viagra, Cialis och Lovegra från globala läkemedelsföretag.</p>

                    <h3>3. Hur kan jag betala för min beställning?</h3>
                    <p>Vi accepterar kortbetalning och Swish. Efter beställning får du en orderbekräftelse via e-post med betalningsinformation.</p>

                    <h3>4. Hur snabbt levereras min beställning?</h3>
                    <p>Vi samarbetar med PostNord för leverans inom 2–3 arbetsdagar för beställningar betalda före kl. 11.00.</p>

                    <h3>5. Vad händer efter att jag har lagt en beställning?</h3>
                    <p>Efter att du har lagt en beställning, kommer du att få en orderbekräftelse via e-post. Din beställning skickas sedan efter betalning, tillsammans med ett Kolli-ID för spårning.</p>

                    <h3>6. Jag har lagt en beställning men inte fått orderbekräftelse via e-post.</h3>
                    <p>Kontakta oss om du inte fått orderbekräftelse inom 10 minuter efter beställning.</p>

                    <h3>7. Kan jag spåra min beställning?</h3>
                    <p>Ja, du får ett bekräftelsemail med Kolli-ID för spårning efter att beställningen skickats.</p>

                    <h3>8. Är mina personuppgifter säkra hos ViagraShop?</h3>
                    <p>Ja, vi raderar dina personuppgifter efter att beställningen skickats enligt dataskyddslagen.</p>

                    <h3>9. Kan jag ångra eller returnera mitt köp?</h3>
                    <p>Ångerrätt gäller inte för läkemedel. Därför kan du inte ångra ditt köp efter att ordern lagts.</p>

                    <h3>10. Vad särskiljer ViagraShop från andra aktörer på marknaden?</h3>
                    <p>Vi garanterar snabb leverans inom Sverige på 2–3 arbetsdagar till förmånliga priser.</p>

                    <h3>11. Kommer produkterna i originalförpackningar?</h3>
                    <p>Ja, alla produkter kommer i originalförpackningar från Pfizer, Eli Lilly och Ajanta.</p>

                    <h3>12. Är det säkert att köpa potensmedel från ViagraShop?</h3>
                    <p>Ja, vi garanterar äkta produkter och diskret leverans.</p>

                    <h3>13. Vilken åldersgräns gäller för att handla hos ViagraShop?</h3>
                    <p>Du måste vara minst 18 år gammal för att handla på vår webbplats.</p>

                    <h3>14. Vad gör jag om jag har en reklamation?</h3>
                    <p>Kontakta oss inom 5 dagar efter mottagandet vid reklamation av defekta produkter.</p>

                    <h3>15. Kan jag köpa potensmedel utan recept hos ViagraShop?</h3>
                    <p>Ja, du kan köpa potensmedel utan recept hos oss.</p>

                    <h3>16. Är det lagligt att köpa potensmedel på nätet?</h3>
                    <p>Ja, enligt EU-lagstiftningen är det lagligt att köpa potensmedel från nätet.</p>

                    <h3>17. Hur får jag en rabattkod?</h3>
                    <p>Du kan få en rabattkod som återkommande kund, via våra samarbetspartners eller lämna ett <a href='/omdomen' className="nav-link">omdöme.</a></p>

                    <h3>18. Skickar ni utrikes?</h3>
                    <p>Ja, vi levererar även till Danmark och Finland med snabb och säker frakt.</p>
                </article>
            </main>
        </>
    );
};

export default FAQ;
