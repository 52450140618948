import React from "react";
import { Helmet } from "react-helmet";
import { Erbjudande } from "../home/filter/Erbjudande";

const erbjudande = () => {
  return (
    <>
      <Helmet>
        <title>ViagraShop - Köp Potensmedel som Viagra och Cialis Online från Sverige i vår viagrastore!</title>
        <meta 
          name="description" 
          content="Upptäck förmånliga paket på potensmedel som Viagra, Cialis och Kamagra i vår viagrastore. Beställ enkelt online från Sverige med snabb och diskret leverans." 
        />
        <meta 
          name="keywords" 
          content="potensmedel paket, köpa Viagra paket, köpa Cialis paket, Kamagra erbjudanden, potensmedel online, potensmedel utan recept, snabba leveranser potensmedel, paket erbjudanden potensmedel"
        />
        <link rel="canonical" href="https://viagrashop.se/erbjudande" />

        {/* Open Graph */}
        <meta property="og:title" content="ViagraShop - Köp Potensmedel som Viagra och Cialis Online från Sverige i vår viagrastore!" />
        <meta 
          property="og:description" 
          content="Upptäck förmånliga paket på potensmedel som Viagra, Cialis och Kamagra i vår viagrastore. Beställ enkelt online från Sverige med snabb och diskret leverans." 
        />
        <meta property="og:url" content="https://viagrashop.se/erbjudande" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:title" content="ViagraShop - Köp Potensmedel som Viagra och Cialis Online från Sverige i vår viagrastore!" />
        <meta 
          name="twitter:description" 
          content="Upptäck förmånliga paket på potensmedel som Viagra, Cialis och Kamagra i vår viagrastore. Beställ enkelt online från Sverige med snabb och diskret leverans." 
        />
        <meta name="twitter:url" content="https://viagrashop.se/erbjudande" />
        <meta name="twitter:card" content="summary" />

        {/* X-Content-Type-Options */}
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <main>
        <article className="main-container">
          <section className="text-center">
          </section>
          <section className="container-section-products">
            <Erbjudande />
          </section>
        </article>
      </main>
    </>
  );
}

export default erbjudande;
