import React from "react";
import { Helmet } from "react-helmet";
import { Potensmedel } from "../home/filter/Potensmedel";


const Butik = () => {
  return (
    <>
      <Helmet>
        <title>ViagraShop - Köp potensmedel online i vår viagrastore!</title>
        <meta name="description" content="Här kan du köpa potensmedel receptfritt från Sverige i vår viagrastore." />
        <meta name="keywords" content="viagrastore, Potensmedel receptfritt, potensmedel billigt, billiga potensmedel, köpa potensmedel inrikes, köp potensmedel i sverige, köp potensmedel online"/>
        <link rel="canonical" href="https://viagrashop.se/butik" />

        {/* Open Graph */}
        <meta property="og:title" content="ViagraShop - Köp potensmedel online i vår viagrastore!" />
        <meta property="og:description" content="Här kan du köpa potensmedel receptfritt från Sverige i vår viagrastore." />
        <meta property="og:url" content="https://viagrashop.se/butik" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:title" content="ViagraShop - Köp potensmedel online i vår viagrastore!" />
        <meta name="twitter:description" content="Här kan du köpa potensmedel receptfritt från Sverige i vår viagrastore." />
        <meta name="twitter:url" content="https://viagrashop.se/butik" />
        <meta name="twitter:card" content="summary" />

         {/* X-Content-Type-Options */}
         <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <main>
        <article className="main-container">
          <section className="text-center">
          <h2>Alla potensmedel</h2>
          </section>
          <section className="container-section-products">
          <Potensmedel />
          </section>
        </article>
      </main>
    </>
  );
}

export default Butik;
